import { ImLinkedin, ImMail3, ImGithub } from "react-icons/im";
export const ContactData = [
  {
    link: "mailto:inciyesiltepe@gmail.com",
    icon: <ImMail3 className="w-full h-full" />,
    text: "Email",
  },
  {
    link: "https://www.linkedin.com/in/inci-yesiltepe-113336196/?originalSubdomain=nl",
    icon: <ImLinkedin className="w-full h-full" />,
    text: "LinkedIn",
  },
  {
    link: "https://github.com/ttnvmbr",
    icon: <ImGithub className="w-full h-full" />,
    text: "Github",
  },
];
