import * as React from "react";

function Desk() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0, 0, 353, 517"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#ECE2D0" d="M36 0h85v244H36z" />
      <path
        fill="#ECE2D0"
        fillOpacity={0.4}
        d="M37.729 231.127l83.192 12.727-36.899 241.194L.83 472.321zM230.899 231l84.022 12.854-41.698 272.564-84.022-12.854zM134.648 231.115l83.272 12.74-39.262 256.64-83.272-12.739z"
      />
      <path fill="#ECE2D0" d="M133 0h85v244h-85zM230 0h85v244h-85z" />
      <g filter="url(#prefix__filter0_i_38_346)">
        <path
          d="M169.365 166.678l-6.36 6.062c-.828 1.216.15 2.689 1.776 2.579l21.174-.081c1.445.109 2.579-1.626 1.655-2.83l-5.847-5.649-.645-4.186-11.411-.335-.342 4.44v0z"
          fill="#6D2E46"
          stroke="#582438"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148.116 121.867L148 161.784c.711 2.362 1.842 3.023 3.415 2.998l48.499-.072c2.359-.019 3.007-1.936 2.87-3.954l.216-38.833c-.069-2.45-1.357-2.769-3.221-2.923l-48.176.047c-2.212.11-3.227 1.015-3.487 2.82z"
          fill="#6D2E46"
          stroke="#582438"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M149.441 122.099l-.153 30.714c.025 1.063.545 2.1 1.691 2.222l48.176.081c1.749.198 2.491-.529 2.545-1.989l.034-30.54c.194-1.773-1.25-2.431-2.801-2.302l-46.985.112c-1.605-.075-2.422.527-2.507 1.702v0z"
          fill="#A26769"
          stroke="#441A2A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M178.711 159.436a2.542 2.542 0 01-.707 1.786 2.41 2.41 0 01-2.682.55 2.49 2.49 0 01-1.326-1.371 2.562 2.562 0 01-.179-.965 2.571 2.571 0 01.179-.966 2.51 2.51 0 01.529-.821c.227-.235.498-.421.797-.549a2.405 2.405 0 012.682.549 2.527 2.527 0 01.707 1.787v0z"
          fill="#D5B9B2"
          stroke="#A26769"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g filter="url(#prefix__filter1_i_38_346)">
          <path
            d="M151.581 122.4l-.078 30.225h47.869v-30.147l-47.791-.078z"
            fill="#221D23"
          />
          // screen
          <animate
            id="anim5"
            attributeName="opacity"
            from="1"
            to="0"
            dur="3s"
            begin="0s; anim6.end"
            fill="freeze"
          />
          <animate
            id="anim6"
            attributeName="opacity"
            from="0"
            to="1"
            dur="3s"
            begin="anim5.end"
            fill="freeze"
          />
        </g>
        <path
          d="M151.581 122.4l-.078 30.225h47.869v-30.147l-47.791-.078z"
          stroke="#441A2A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M176.204 121.397a.422.422 0 01-.136.324.487.487 0 01-.344.136.487.487 0 01-.448-.285.422.422 0 01-.032-.175.422.422 0 01.136-.325.468.468 0 01.157-.1.517.517 0 01.374 0 .468.468 0 01.261.25.422.422 0 01.032.175v0z"
          fill="#231F20"
          stroke="#441A2A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g
        style={{
          mixBlendMode: "hard-light",
        }}
        filter="url(#prefix__filter2_f_38_346)"
        fill="#221D23"
      >
        <path d="M169.167 341.294c.181-1.321-.454-2.51-1.419-2.657l-20.647-3.134c-.965-.147-1.894.806-2.075 2.126-.182 1.321.454 2.511 1.418 2.657l20.648 3.134c.965.147 1.894-.805 2.075-2.126z" />
        <path d="M179.226 287.652c.244-.811.044-1.652-.448-1.878-.492-.226-1.089.248-1.334 1.059l-15.422 51.175c-.245.811-.044 1.652.448 1.878.492.226 1.089-.249 1.334-1.061l15.422-51.173zM151.216 286.306c.035-.801.558-1.454 1.168-1.461.609-.006 1.076.637 1.041 1.437l-2.211 50.466c-.035.8-.558 1.454-1.168 1.46-.61.006-1.076-.638-1.041-1.437l2.211-50.465z" />
        <path d="M172.702 307.529c.067-.488-.167-.928-.524-.982l-20.538-3.118c-.356-.054-.699.298-.766.786-.067.488.168.927.524.981l20.538 3.118c.356.054.699-.298.766-.785zM149.956 341.761s4.039 2.782 8.804 3.505c4.765.724 9.386-.744 9.386-.744" />
      </g>
      <g
        style={{
          mixBlendMode: "hard-light",
        }}
        filter="url(#prefix__filter3_f_38_346)"
        fill="#221D23"
      >
        <path
          d="M257.525 374.445l-43.734-7.96c-2.174-.395-3.666-2.487-3.333-4.67l8.797-57.765a3.862 3.862 0 014.537-3.238l43.735 7.96c2.173.396 3.666 2.487 3.333 4.671l-8.797 57.764a3.863 3.863 0 01-4.538 3.238z"
          stroke="#221D23"
          strokeWidth={2.108}
          strokeMiterlimit={10}
        />
        <path d="M247.721 364.653l-22.479-4.091c-5.434-.989-9.165-6.217-8.333-11.677l4.956-32.544a9.657 9.657 0 0111.344-8.095l22.48 4.091c5.433.989 9.164 6.217 8.333 11.677l-4.957 32.544a9.657 9.657 0 01-11.344 8.095z" />
        <path
          d="M226.221 328.137c1.605.293 2.603 2.523 2.229 4.982-.375 2.459-1.98 4.216-3.586 3.923-1.605-.292-2.603-2.522-2.229-4.981.375-2.459 1.98-4.216 3.586-3.924zM110.235 347.637l-45.702-8.318a3.106 3.106 0 01-2.5-3.503l9.098-59.741a2.898 2.898 0 013.403-2.429l45.702 8.318a3.106 3.106 0 012.5 3.503l-9.098 59.742a2.896 2.896 0 01-3.403 2.428z"
          stroke="#221D23"
          strokeWidth={2.108}
          strokeMiterlimit={10}
        />
        <path d="M98.208 337.441L78.206 333.8c-6.118-1.113-10.318-6.999-9.382-13.147.936-6.147 6.655-10.228 12.773-9.115l20.001 3.641c6.118 1.113 10.319 7 9.382 13.147-.936 6.148-6.654 10.228-12.772 9.115zM100.976 309.918l-17.232-3.137c-6.883-1.252-11.608-7.874-10.555-14.79 1.053-6.916 7.487-11.507 14.37-10.254l17.231 3.136c6.883 1.253 11.609 7.875 10.555 14.791-1.053 6.916-7.486 11.506-14.369 10.254z" />
        <path
          d="M88.927 322.31c.702.128 1.14 1.104.975 2.18-.164 1.075-.866 1.844-1.568 1.716-.703-.128-1.14-1.104-.976-2.179.164-1.076.867-1.845 1.569-1.717zM93.292 293.648c.703.128 1.14 1.103.975 2.179-.163 1.076-.866 1.844-1.568 1.717-.703-.128-1.14-1.104-.975-2.18.163-1.076.866-1.844 1.568-1.716z"
          stroke="#221D23"
          strokeWidth={2.108}
          strokeMiterlimit={10}
        />
        <path d="M71.091 276.34c.359-2.356-.977-4.563-2.984-4.928-2.007-.365-3.925 1.249-4.284 3.605l-9.877 64.855c-.359 2.356.977 4.562 2.984 4.928 2.007.365 3.925-1.249 4.284-3.606l9.877-64.854zM283.278 286.643c.5-3.288-.721-6.25-2.728-6.615-2.007-.366-4.04 2.004-4.541 5.292l-13.782 90.498c-.501 3.288.72 6.25 2.727 6.615 2.008.365 4.041-2.004 4.541-5.292l13.783-90.498z" />
        <path
          d="M41.712 346.626c.376-2.469 2.634-4.117 5.042-3.679l231.134 42.068c2.408.438 4.056 2.796 3.679 5.266l-2.043 13.418c-.376 2.47-2.634 4.117-5.042 3.679L43.349 365.311c-2.409-.439-4.056-2.797-3.68-5.267l2.043-13.418v0z"
          stroke="#221D23"
          strokeWidth={2.108}
          strokeMiterlimit={10}
        />
        <path
          d="M271.855 380.485c.28-1.844-.468-3.517-1.672-3.736L54.313 337.46c-1.204-.22-2.408 1.098-2.69 2.942-.28 1.844.469 3.517 1.673 3.736l215.87 39.29c1.204.219 2.408-1.099 2.689-2.943z"
          stroke="#221D23"
          strokeWidth={2.108}
          strokeMiterlimit={10}
        />
      </g>
      <g filter="url(#prefix__filter4_f_38_346)">
        <mask
          id="prefix__a"
          style={{
            maskType: "alpha",
          }}
          maskUnits="userSpaceOnUse"
          x={13}
          y={360}
          width={96}
          height={87}
        >
          <path
            transform="matrix(.9829 .1841 -.1531 .98821 24.567 360.315)"
            fill="#C4C4C4"
            d="M0 0h85.102v71.293H0z"
          />
        </mask>
        <g mask="url(#prefix__a)">
          <path
            d="M77.32 370.611l3.819-24.651s.344-1.557 1.235-1.469c.89.089.802 1.677 1.19 1.787.387.11 1.352 1.358 1.336 2.402-.016 1.043-1.386 5.724-1.642 8.935-.255 3.212.669 8.499.669 8.499s3.99-2.226 5.603-2.994c1.612-.769 4.624-1.778 4.584-2.678-.04-.899.973-3.278 1.532-3.766.559-.487 1.076-1.642 1.796-1.918.719-.277 1.628.091 2.064-.124.436-.214.701-.362 1.077-.193.376.169 1.188 3.098 1.741 3.245.552.147 1.943 3.328 2.566 3.367.623.039 2.199-.258 3.5.183 1.301.441 1.907 1.401 1.822 1.851-.085.45-2.299 1.843-2.299 1.843s1.172 1.177.848 1.709c-.324.532-3.555 2.588-4.835 3.092-1.279.503-3.251.853-3.251.853l-.929.848s-4.443 3.056-4.998 4.038c-.555.983-1.86 6.566-2.296 7.422-.436.856-4.426 4.257-4.873 5.062-.447.805-4.237 10.282-12.61 12.255-7.942 1.87-15.382-2.091-18.7-2.515-3.316-.424-7.844 1.212-9.476 1.887-1.632.674-11.416 8.718-14.76 12.634-3.531 4.134-5.099 9.514-2.345 12.523 3.243 3.544 2.399 3.892 1.919 4.587-.48.695-1.854.76-3.135-.085-1.282-.846-4.096-2.954-4.49-7.175-.342-3.68 1.934-12.477 11.061-20.446 9.84-8.591 15.376-10.354 15.589-11.203.212-.849-.03-1.882-.03-1.882l.05-.753-.069-1.107.45-.923s.174-1.647.744-2.725c.57-1.078 2.043-3.117 2.059-3.833.015-.716-.572-3.69-.155-4.304.418-.613 4.136-2.78 4.661-3.571.525-.79 1.468-2.194 1.997-2.49.529-.296 1.198-.544 1.301-.646.104-.103.216-.467.216-.467l7.31 1.369-.144.927s-.265.369-.311.533c-.046.165-.11.967-.858 1.247-.748.28-3.274.282-3.429.398a2.046 2.046 0 01-.52.27c-.214.083-1.065 1.158-1.493 1.448-.427.291-1.403 1.096-1.403 1.096l.17.284s2.589.01 5.652.51c3.064.499 4.038 1.429 4.497 1.487.459.058 1.159-.304 1.41-.628.252-.323.562-1.154.744-1.292.183-.139 1.903-3.051 2.126-3.454.225-.399.68-1.005.984-1.146.304-.14.73-.149.73-.149"
            fill="#221D23"
          />
          <animateTransform
            id="anim3"
            begin="0s; anim4.end"
            attributeName="transform"
            attributeType="XML"
            type="translate"
            from="0 0"
            to="10 0"
            dur="3s"
            fill="freeze"
          />
          <animateTransform
            id="anim4"
            attributeName="transform"
            attributeType="XML"
            type="translate"
            begin="anim3.end"
            fill="freeze"
            from="10 0"
            to="0 0"
            dur="3s"
          />
        </g>
      </g>
      <g
        style={{
          mixBlendMode: "hard-light",
        }}
        filter="url(#prefix__filter5_f_38_346)"
        fill="#221D23"
        stroke="#221D23"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M141.92 391.139l-5.406-6.272c-.647-1.173.503-2.216 2.076-1.816l10.257 1.84 10.403 2.255c1.425.183 2.302 1.848 1.24 2.677l-6.456 3.606-1.184 3.373-11.185-1.89.255-3.773z" />
        <path d="M114.419 430.724l5.977-38.413c1.054-2.137 2.259-2.558 3.791-2.235l47.336 9.291c2.3.467 2.641 2.435 2.198 4.349l-5.713 37.39c-.441 2.343-1.747 2.406-3.591 2.199l-47.024-9.206c-2.143-.526-2.996-1.59-2.974-3.375z" />
      </g>
      <path
        d="M164.621 138.092a.957.957 0 01-.361-.235.94.94 0 01-.284-.693.988.988 0 01.537-.879l3.907-1.953a.967.967 0 01.439-.098c.267 0 .495.098.684.293a.918.918 0 01.293.684.946.946 0 01-.283.693 1.042 1.042 0 01-.381.235l-2.041 1.015 2.158 1.084c.091.046.179.108.264.186a.966.966 0 01.283.693.93.93 0 01-.293.694.936.936 0 01-.684.283.967.967 0 01-.547-.156l-3.691-1.846zm15.469-8.584a.985.985 0 01.468.605.955.955 0 01-.097.762l-7.764 13.447a.958.958 0 01-.605.459.955.955 0 01-.762-.097.967.967 0 01-.459-.606.932.932 0 01.098-.752l7.763-13.447a.958.958 0 01.606-.469.944.944 0 01.752.098zm6.679 8.584a.96.96 0 00.362-.235.943.943 0 00.283-.693.966.966 0 00-.283-.693.992.992 0 00-.254-.186l-3.906-1.953a.975.975 0 00-.44-.098.948.948 0 00-.693.293.933.933 0 00-.284.684c0 .273.095.504.284.693.117.111.244.189.381.235l2.041 1.015-2.159 1.084a.963.963 0 00-.547.879c0 .274.095.505.284.694a.966.966 0 00.693.283.964.964 0 00.547-.156l3.691-1.846z"
        fill="#000"
      />
      <g filter="url(#prefix__filter6_i_38_346)">
        <path
          d="M179.182 234.078c0 1.481-.918 2.683-2.052 2.683h-24.246c-1.134 0-2.052-1.202-2.052-2.683 0-1.482.918-2.683 2.052-2.683h24.246c1.134 0 2.052 1.201 2.052 2.683z"
          fill="#6D2E46"
        />
        <path
          d="M179.174 286.149c.075.802-.361 1.532-.975 1.631-.613.098-1.172-.472-1.247-1.274l-4.75-50.597c-.076-.802.361-1.532.974-1.631.613-.098 1.172.472 1.247 1.274l4.751 50.597zM150.84 286.149c-.075.802.361 1.532.975 1.631.613.098 1.172-.472 1.247-1.274l4.75-50.597c.076-.802-.361-1.533-.974-1.631-.613-.099-1.172.472-1.247 1.274l-4.751 50.597z"
          fill="#6D2E46"
        />
        <path
          d="M177.917 271.771c0 .548-.34.991-.758.991h-24.118c-.419 0-.758-.443-.758-.991 0-.546.339-.99.758-.99h24.118c.418 0 .758.444.758.99z"
          fill="#6D2E46"
        />
        <path
          d="M154.326 231.395s5.085-2.439 10.681-2.439c5.595 0 10.681 2.439 10.681 2.439"
          fill="#221D23"
        />
        <path
          d="M272.816 200.811h-44.97a4 4 0 00-4 4v49.562a4 4 0 004 4h44.97a4 4 0 004-4v-49.562a4 4 0 00-4-4z"
          fill="#A26769"
          stroke="#6D2E46"
          strokeWidth={2.108}
          strokeMiterlimit={10}
        />
        <path
          d="M261.594 207.641h-23.271c-5.523 0-10 4.477-10 10v25.854c0 5.523 4.477 10 10 10h23.271c5.523 0 10-4.477 10-10v-25.854c0-5.523-4.477-10-10-10z"
          fill="#D5B9B2"
        />
        <path
          d="M234.468 235.446c1.648 0 2.984-1.747 2.984-3.903 0-2.155-1.336-3.902-2.984-3.902-1.649 0-2.985 1.747-2.985 3.902 0 2.156 1.336 3.903 2.985 3.903z"
          fill="#221D23"
          stroke="#6D2E46"
          strokeWidth={2.108}
          strokeMiterlimit={10}
        />
        <path
          d="M121.621 200.811h-46.97a3 3 0 00-3 3v51.562a3 3 0 003 3h46.97a3 3 0 003-3v-51.562a3 3 0 00-3-3z"
          fill="#A26769"
          stroke="#6D2E46"
          strokeWidth={2.108}
          strokeMiterlimit={10}
        />
        <path
          d="M109.643 207.641h-23.76c-5.387 0-9.755 4.368-9.755 9.756s4.368 9.756 9.756 9.756h23.759c5.388 0 9.756-4.368 9.756-9.756s-4.368-9.756-9.756-9.756zM108.423 231.543h-21.32c-6.061 0-10.975 4.914-10.975 10.976 0 6.062 4.914 10.976 10.975 10.976h21.32c6.062 0 10.976-4.914 10.976-10.976 0-6.062-4.914-10.976-10.976-10.976z"
          fill="#D5B9B2"
        />
        <path
          d="M96.458 219.104c.72 0 1.305-.764 1.305-1.707 0-.943-.584-1.708-1.305-1.708s-1.306.765-1.306 1.708.585 1.707 1.306 1.707zM96.458 244.226c.72 0 1.305-.764 1.305-1.707 0-.943-.584-1.707-1.305-1.707s-1.306.764-1.306 1.707c0 .943.585 1.707 1.306 1.707z"
          fill="#231F20"
          stroke="#6D2E46"
          strokeWidth={2.108}
          strokeMiterlimit={10}
        />
        <path
          d="M71.651 279.496c0 2.882-1.67 5.219-3.73 5.219s-3.73-2.337-3.73-5.219v-79.32c0-2.882 1.67-5.219 3.73-5.219s3.73 2.337 3.73 5.219v79.32zM284.277 279.496c0 2.882-1.67 5.219-3.73 5.219-2.061 0-3.731-2.337-3.731-5.219v-79.32c0-2.882 1.67-5.219 3.731-5.219 2.06 0 3.73 2.337 3.73 5.219v79.32z"
          fill="#6D2E46"
        />
        <path
          d="M53 190.966c0 2.204 2.004 3.991 4.476 3.991h237.246c2.472 0 4.476-1.787 4.476-3.991v-11.974c0-2.205-2.004-3.992-4.476-3.992H57.476c-2.472 0-4.476 1.787-4.476 3.992v11.974z"
          fill="#6D2E46"
          stroke="#6D2E46"
          strokeWidth={2.108}
          strokeMiterlimit={10}
        />
        <path
          d="M288.007 197.884c0 1.617-1.002 2.927-2.238 2.927H64.191c-1.236 0-2.238-1.31-2.238-2.927 0-1.616 1.002-2.927 2.238-2.927h221.578c1.236 0 2.238 1.311 2.238 2.927z"
          fill="#A26769"
          stroke="#6D2E46"
          strokeWidth={2.108}
          strokeMiterlimit={10}
        />
      </g>
      <g filter="url(#prefix__filter7_d_38_346)">
        <path
          d="M96.509 175.869v23.793s.118 1.521 1.158 1.594c1.04.073 1.221-1.431 1.687-1.466.465-.035 1.797-1.037 1.965-2.019.167-.981-.574-5.612-.296-8.669.278-3.057 2.284-7.854 2.284-7.854s4.193 2.788 5.91 3.793c1.718 1.004 5.002 2.48 4.796 3.317-.206.836.535 3.245 1.091 3.801.555.555.945 1.729 1.723 2.115.778.386 1.889.2 2.353.478.463.278.741.463 1.204.371.463-.093 1.918-2.698 2.58-2.738.662-.041 2.828-2.78 3.551-2.707.724.072 2.484.628 4.059.443 1.574-.186 2.443-.979 2.426-1.416-.018-.437-2.316-2.133-2.316-2.133s1.557-.898 1.279-1.454c-.278-.555-3.627-3.052-5.01-3.749-1.382-.697-3.587-1.372-3.587-1.372l-.917-.959s-4.566-3.646-5.03-4.665c-.463-1.019-.97-6.485-1.318-7.365-.349-.88-4.332-4.77-4.703-5.604-.37-.834-3.04-10.389-12.32-13.71-8.8-3.149-18.064-.741-21.955-.926-3.89-.185-8.807-2.516-10.564-3.435-1.756-.919-11.575-10.182-14.725-14.444-3.324-4.497-4.169-9.819-.465-12.158 4.36-2.754 3.452-3.228 3.024-3.965-.428-.736-1.996-1.039-3.62-.47-1.625.568-5.238 2.05-6.442 5.94-1.05 3.392 0 12.043 9.078 21.121 9.786 9.787 15.84 12.413 15.933 13.247.093.833-.37 1.76-.37 1.76l-.076.715-.277 1.026.352.945s-.092 1.575.37 2.687c.464 1.111 1.796 3.282 1.686 3.957-.11.674-1.314 3.361-.944 4.009.37.649 4.261 3.335 4.725 4.169.463.833 1.296 2.315 1.852 2.686s1.28.721 1.382.835c.1.115.164.476.164.476h8.652v-.895s-.24-.393-.263-.555c-.023-.162.046-.926-.764-1.32s-3.716-.84-3.873-.976a2.48 2.48 0 00-.55-.344c-.232-.116-1.02-1.274-1.46-1.621-.44-.348-1.418-1.274-1.418-1.274l.245-.236s2.98.445 6.593.514c3.613.07 4.898-.631 5.437-.605.538.026 1.279.489 1.51.837.232.347.44 1.181.626 1.343.185.162 1.644 3.196 1.829 3.613.187.413.604 1.062.928 1.247.325.185.812.269.812.269"
          fill="#221D23"
        />
        // cat
        <animateTransform
          id="anim1"
          begin="0s; anim2.end"
          attributeName="transform"
          attributeType="XML"
          type="translate"
          from="0 0"
          to="10 0"
          dur="3s"
          fill="freeze"
        />
        <animateTransform
          id="anim2"
          attributeName="transform"
          attributeType="XML"
          type="translate"
          begin="anim1.end"
          fill="freeze"
          from="10 0"
          to="0 0"
          dur="3s"
        />
      </g>
      <defs>
        <filter
          id="prefix__filter0_i_38_346"
          x={147}
          y={118}
          width={57}
          height={62.325}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="shape" result="effect1_innerShadow_38_346" />
        </filter>
        <filter
          id="prefix__filter1_i_38_346"
          x={151.003}
          y={121.9}
          width={48.869}
          height={35.226}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="shape" result="effect1_innerShadow_38_346" />
        </filter>
        <filter
          id="prefix__filter2_f_38_346"
          x={140.995}
          y={280.845}
          width={42.335}
          height={68.621}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={2}
            result="effect1_foregroundBlur_38_346"
          />
        </filter>
        <filter
          id="prefix__filter3_f_38_346"
          x={34.566}
          y={267.36}
          width={252.824}
          height={145.148}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={2}
            result="effect1_foregroundBlur_38_346"
          />
        </filter>
        <filter
          id="prefix__filter4_f_38_346"
          x={19.95}
          y={356.315}
          width={92.265}
          height={77.545}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={2}
            result="effect1_foregroundBlur_38_346"
          />
        </filter>
        <filter
          id="prefix__filter5_f_38_346"
          x={109.425}
          y={377.959}
          width={69.458}
          height={70.429}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={2}
            result="effect1_foregroundBlur_38_346"
          />
        </filter>
        <filter
          id="prefix__filter6_i_38_346"
          x={47.946}
          y={173.946}
          width={252.306}
          height={117.845}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={-20} dy={80} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="shape" result="effect1_innerShadow_38_346" />
        </filter>
        <filter
          id="prefix__filter7_d_38_346"
          x={40}
          y={112}
          width={97}
          height={97.259}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_38_346"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_38_346"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default Desk;
