export const skillData = [
  {
    title: "Languages",
    titleNl: "talen",
    items: [
      "JavaScript",
      "HTML",
      "CSS",
      "TypeScript",
      "C#",
      "PHP",
      "SQL",
      "GraphQL",
    ],
  },
  {
    title: "Frameworks/Game-engines",
    titleNl: "Frameworks/Game-engines",
    items: [
      "React",
      "Unity",
      "NextJS",
      "ML5JS",
      "ThreeJS",
      "Laravel",
      "Sanity",
      "Tailwind",
      "Bootstrap",
    ],
  },
  {
    title: "Tools/Other",
    titleNl: "Hulpmiddelen/overig",
    items: ["Scrum", "Jira", "Git", "Figma", "Trello", "Miro"],
  },
];
